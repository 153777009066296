.planning {

  #planning_filter div {
    @include flex;
    @include flex-align(left, middle);
  }
}

.planning__notfound {
  @include flex;
  @include flex-direction(column);
  @include flex-align(center, middle);
}