.header {
  background: url("../img/header.jpg") no-repeat 50% 100%;
  height: 305px;
  width: 100%;
  box-shadow: inset 0 0 0 1000px rgba(51, 51, 51, 0.7);
  margin-bottom: 1rem;

  @include breakpoint(small only) {
    display: none;
    position: sticky;
    top: 65px;
    z-index: 35;
  }

  div.humi__menu, ul {
    width: 100%;
    height: 100%;
  }

  ul {
    height: 265px;
    @include update-list();
    padding-bottom: 35px;

    @include breakpoint(small only) {
      height: 100%;
      padding: 0;
      margin-left: 1rem;
    }

    li {
      font-size: 35px;
      text-transform: uppercase;
      padding: 1rem 1.25rem;

      @include breakpoint(small only) {
        padding: 0;
        font-size: 30px;
      }

      a {
      color: white;
      text-decoration: none;
      font-weight: bold;

        &:hover {
          @include stroke(3, $purple);
        }

      }
    }
  }
}

.menu__top-bar {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: $grey-text;
  color: white;
  height: 65px;
  z-index: 9;

  @include breakpoint(medium up) {
    display: none;
  }

  img {
    z-index: 10;
    max-height: 45px;
    margin-right: 20px;
    cursor: pointer;
  }
}

.menu__mobile {
  display: none;

  &.opened {
    display: block;
  }
}

.navbar-nav {
  @include flex;
  @include flex-align(left, middle);
  background-color: black;
  color: white;
  margin: 0;
  height: 50px;

  li:first-of-type {
    margin-right: 2.5rem;
  }

  .dropdown-toggle {
    margin: 1.5rem 1.5rem;
    text-align: center;
  }

  a {
    color: white;
  }

  .dropdown-menu {
      background-color: black;
      top: 50px;
      position: absolute;
      margin: 0;
      display: none;

      &.opened {
        display: block;
        z-index: 11;
      }

      & > li {
        margin: 0.5rem 0;
        padding: 0.7rem 0.6rem;
      }
  }

  ul, li {
    list-style-type: none;
  }
  .divider {
    display: none;
  }
}