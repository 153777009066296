.extension {
  @include flex;
  @include flex-align(right, middle);

  @include breakpoint(medium down) {
    @include flex-direction(column);
  }
}

.extension__w-link {
  @include flex;
}
