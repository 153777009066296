/* Colors */
$purple: #694269;
$beige: #d7d2d2;
$red: #9a3c3c;
$grey-text: #333333;

/* Fonts */
@font-face {
  font-family: 'Arsenal';
  src: url('../fonts/Arsenal/Arsenal-Regular.ttf');
}

@font-face {
  font-family: 'Open-Sans';
  src: url('../fonts/Open_Sans/OpenSans-Regular.ttf');
}

$title-font-family: 'Arsenal','Arial','Helvetica','sans-serif';
$subtitle-font-family: 'Open-Sans','Arial','Helvetica','sans-serif';


h1, h2, h3, h4 {
  font-family: $title-font-family;
}

p, ul, li, span, div {
  font-family: $subtitle-font-family;
}

p {
  font-size: 17px;
  margin: 24px 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
  font-weight: bold;
}

.container, .content {
  background-color: $beige;
}

html, body {
  height: 100%;
}