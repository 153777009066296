.youtube__w-videos {
  @include flex;
  @include flex-align(justify, top);

  @include breakpoint(medium only) {
    @include flex-align(center, middle);
    flex-wrap: wrap;
  }


  @include breakpoint(small only) {
    @include flex-direction(column);
  }
}

.youtube__subscribe {
  @include flex;
  @include flex-align(right, middle);

  @include breakpoint(medium down) {
    @include flex-align(center, middle);
  }
}

.youtube__more {
  @include flex;
  @include flex-direction(column);
  @include flex-align(justify, middle);
}