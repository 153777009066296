.page {
  @include flex;
  @include flex-direction(column);
  @include flex-align(center, middle);

}

.page--404 {
  @include flex;
  @include flex-direction(row);
  @include flex-align(left, top);

  @include breakpoint(small only) {
    @include flex-direction(column-reverse);
  }
}