.footer {
  margin-top: auto;
  width: 100%;
  background-color: $grey-text;
  color: white;

  a {
    color: white;
  }

  p {
    margin: 0;
    padding: 1rem 0;

    @include breakpoint(small only) {
      margin-left: 0.975rem;
    }
  }
}