.extension {
  width: 100%;
  margin: 1rem 0;
}

.extension__image {
  max-height: 100px;
}

.extension__link {
  display: block;

  &:first-of-type {
    margin-right: 15px;
  }
}

.extension__text {
  color: $purple;
  text-align: center;
  margin: 0 2rem 0 0;
  font-size: 22px;
  font-weight: bold;

  @include breakpoint(medium down) {
    margin: 0 1rem 1.5rem 1rem;
  }
}