.faq {
  width: 100%;
}

.faq__title {
  color: $purple;
}

.faq__question {
  color: $red;
  margin: 0 0 1rem 0;
}

.faq__reply {
  color: $grey-text;
  margin: 0;
}

.faq__w-question {
  margin-bottom: 1rem;
}

.faq__link {
  color: $purple;
}