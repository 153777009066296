/**
 * Remove bullets and margins, paddings from lists
 * @param $margin:      margin:              0
 * @param $padding:     padding:             0
 * @param $list-type:   list-style-type:     none
 */
@mixin update-list(
    $margin: 0,
    $padding: 0,
    $list-type: none) {

    margin: $margin;
    padding: $padding;
    list-style-type: $list-type;
}

/**
*Stroke font-character
*@param  {Integer} $stroke - Stroke width
*@param  {Color}   $color  - Stroke color
*@return {List}            - text-shadow list
*/
@function stroke($stroke, $color) {
    $shadow: ();
    $from: $stroke*-1;
    @for $i from $from through $stroke {
        @for $j from $from through $stroke {
            $shadow: append($shadow, $i*1px $j*1px 0 $color, comma);
        }
    }
    @return $shadow;
}

/*
 *Stroke font-character
 *@param  {Integer} $stroke - Stroke width
 *@param  {Color}   $color  - Stroke color
 *@return {Style}
*/
@mixin stroke($stroke, $color) {
    text-shadow: stroke($stroke, $color);
}