@charset 'utf-8';

@import 'fonts';
@import 'vars';
@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import 'mixins';
@import 'layout';

@include foundation-global-styles;
/*@include foundation-grid;*/
@include foundation-flex-grid;
/*@include foundation-xy-grid-classes;*/
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
// @include foundation-callout;
// @include foundation-card;
// @include foundation-close-button;
// @include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
// @include foundation-responsive-embed;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
// @include foundation-reveal;
// @include foundation-switch;
@include foundation-table;
@include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;
/*@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;*/

// @include foundation-prototype-classes;

// @include motion-ui-transitions;
// @include motion-ui-animations;

// Project components
@import "components/_extensions.layout.scss";
@import "components/_extensions.scss";
@import "components/_fanarts.layout.scss";
@import "components/_fanarts.scss";
@import "components/_faq.layout.scss";
@import "components/_faq.scss";
@import "components/_footer.layout.scss";
@import "components/_footer.scss";
@import "components/_header.layout.scss";
@import "components/_header.scss";
@import "components/_page.layout.scss";
@import "components/_page.scss";
@import "components/_planning.layout.scss";
@import "components/_planning.scss";
@import "components/_twitch.layout.scss";
@import "components/_twitch.scss";
@import "components/_usefull_links.layout.scss";
@import "components/_usefull_links.scss";
@import "components/_youtube.layout.scss";
@import "components/_youtube.scss";