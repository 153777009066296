
.fanarts {
  width: 100%;
}
.fanarts__title {
  color: $purple;
  text-transform: uppercase;
}

.fanarts__description {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;

  &--activated {
    background-color: rgba(0,0,0,0.3);
    display: block;
    cursor: pointer;
  }

  div {
    width: 100%;
    height: 100%;
  }
  h4 {
    color: $purple;
  }

  p {
    color: white;
    margin: 0 1rem 1rem 0;
  }
}

.grid-item {
  margin-bottom: 1rem;
  position: relative;
}
