.planning {
  width: 100%;
  margin: 1rem 0;

  img {
    width: 100%;
  }

  #planning_filter {
    width: 100%;
  }

  form {
    margin-bottom: 1.5rem;
  }

  label {
    color: $red;
    font-size: 1.5rem;
  }

  select {
    max-width: 350px;
    margin: 0 0 0 1rem;
  }
}

.planning__title {
  color: $purple;
  text-transform: uppercase;
}

.planning__notfound {
  h3 {
    color: $purple;

  }
}