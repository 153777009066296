.usefull_link {
  width: 100%;
  margin: 1rem 0;

  thead > tr > th {
    text-align: center;
  }

  @include breakpoint(medium down) {
    tr  {
     td:last-child {
       border-bottom: 0;
     }
    }
  }

  td, th, tr {
    border: 2px solid $purple;
    color: $grey-text;
    padding: 1rem 1.5rem;

    @include breakpoint(medium down) {
      border-left: 0;
      border-right: 0;
    }
  }

  table {
    border-collapse: collapse;

    tr > td:first-of-type {
      color: $red;
      text-align: center;
    }
  }

  th {
    color: $purple;
  }

  a {
    color: $purple;
    text-decoration: none;
  }
}

.usefull_link__title {
  color: $purple;
  text-transform: uppercase;
}