.twitch__w-buttons {
  @include flex;
  @include flex-align(left, middle);

  div {
    @include flex;

    @include breakpoint(medium down) {
      @include flex-direction(column);
      @include flex-align(center, middle);
    }
  }

  @include breakpoint(small only) {
    @include flex-direction(column);
    @include flex-align(center, middle);
  }
}