.fanarts__description {

  &--activated {
    div {
      @include flex;
      @include flex-direction(column);
      @include flex-align(justify, top);
      background-color: rgba(99,99,99,0.5);
    }
  }

  h4 {
    color: $purple;
  }

  p {
    align-self: flex-end;
  }
}