.page {
  background-color: $beige;
  height: 100%;
  width: 100%;
  margin: 1rem 0;

  .error__message {
    color: #5F021F;
    font-size: 1.5rem;
    text-transform: uppercase;
    text-align: left;
  }
}

.page__title {
  color: $purple;
}

.page--404 {
  .error__number {
    font-size: 8rem;
    margin-right: 1rem;
  }

  div {
    text-align: center;
  }

  span {
    font-size: 4rem;
    color: $purple;
  }

  p {
    font-size: 1.5rem;
    color: $grey-text;
  }

  img {
    @include breakpoint(large up) {
      max-width: 502px;
    }
  }
}

.page__form {
  input#_submit {
    @include button($background: $purple);
  }
}