.twitch {
  width: 100%;
  margin: 2rem 0 1rem 0;
}

.twitch__link {
  display: block;
  width: 300px;
  background-color: $purple;
  text-align: center;
  padding: 1rem 2rem;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-family: $subtitle-font-family;

  &:hover, &:active, &:visited, &:focus {
    color: white;
  }
}

.twitch__w-buttons {
  width: 100%;
  margin-top: 1rem;
}

.twitch__link {
  @include breakpoint(medium down) {
    margin-bottom: 1rem;
  }

  &:first-of-type {
    margin-right: 1rem;

    @include breakpoint(medium down) {
      margin-right: 0;
    }
  }

  &:last-of-type {
    margin-left: auto;
    margin-right: 0;
    max-height: 56px;

    @include breakpoint(small only) {
      margin-left: 0;
    }
  }
}