.youtube {
  width: 100%;
  margin: 1rem 0;
}

.youtube__subscribe {
  margin: 0 0 1rem 0;
}

.youtube__title {
  color: $purple;
  text-transform: uppercase;
}

.youtube__video {
  margin-bottom: 1.5rem;
  max-width: 380px;

  @include breakpoint(medium only) {
    &:first-of-type {
      margin-right: 1rem;
    }
  }

  h4 {
    color: $purple;
    margin: 0.3rem 0 0 0;
    font-size: 1.2rem;
  }
}

.youtube__more {
  p {
    color: $purple;
    font-size: 20px;
    margin: 0 0 0.5rem 0;
  }

  a {
    display: block;
    width: 300px;
    background-color: $purple;
    text-align: center;
    padding: 1rem 2rem;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
  }
}