.header {

  div.humi__menu {
    @include flex;
    @include flex-align(center, bottom);
  }

  .socialnetworks {
    @include flex;
    @include flex-align(right, middle);
    width: 100%;
    
    @include breakpoint(small only) {
      display: none;
    }

    a {
      margin: 1rem 0 0 1rem;
    }

    a:last-of-type {
      margin-right: 1rem;
    }

    img {
      height: 25px;
    }
  }

  ul {
    @include flex;
    @include flex-align(center, bottom);
    flex-wrap: wrap;

    @include breakpoint(small only) {
      @include flex-direction(column);
      @include flex-align(left, top);
      flex-wrap: nowrap;
    }
  }
}

.menu__top-bar {
  @include flex;
  @include flex-align(justify, middle);

  .socialnetworks {
    margin-left: 20px;
    img {
    width: 25px;
  }
  }
}

.menu__mobile {
  ul {
    @include flex;
    @include flex-direction(column);
  }
}
